var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Tittel",
      "data-cy": "new-note-title",
      "rules": _vm.validateNotEmpty,
      "required": ""
    },
    model: {
      value: _vm.noteModel.title,
      callback: function callback($$v) {
        _vm.$set(_vm.noteModel, "title", $$v);
      },
      expression: "noteModel.title"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "Innhold",
      "data-cy": "new-note-text"
    },
    model: {
      value: _vm.noteModel.text,
      callback: function callback($$v) {
        _vm.$set(_vm.noteModel, "text", $$v);
      },
      expression: "noteModel.text"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }