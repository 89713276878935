var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.modalData.showModal ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      }
    }
  }, [_c('CourseAddNoteModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType
    },
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      },
      "reloadData": _vm.loadNotes
    }
  })], 1) : _vm._e(), _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.navigateToCourseDashboard
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" " + _vm._s(_vm.$route.params.id) + " - " + _vm._s(_vm.course.courseName) + " ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": "",
            "data-cy": "add-note"
          },
          on: {
            "click": _vm.openAddNoteModal
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Legg til nytt notat ")], 1)];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "ma-4"
  }, [_c('BaseExpansionPanelList', {
    attrs: {
      "items": _vm.notes,
      "canDeleteItems": true,
      "deleteItemWarning": 'Er du helt sikker på at du vil slette dette notatet?'
    },
    on: {
      "removeById": _vm.removeNote
    }
  })], 1)]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }