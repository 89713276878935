var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-expansion-panels', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.openPanels,
      callback: function callback($$v) {
        _vm.openPanels = $$v;
      },
      expression: "openPanels"
    }
  }, _vm._l(_vm.items, function (item, index) {
    return _c('v-expansion-panel', {
      key: index
    }, [_c('v-expansion-panel-header', {
      staticClass: "header"
    }, [_vm._v(_vm._s(item.header))]), _c('v-expansion-panel-content', {
      attrs: {
        "data-cy": "panel-body"
      }
    }, [_c('v-row', [_c('v-col', [_vm._v(" " + _vm._s(item.content) + " ")])], 1), _c('v-row', [_c('v-col', {
      staticClass: "mt-3 text--secondary text-body-2"
    }, [_vm._v(" Opprettet " + _vm._s(item.inserted) + " av " + _vm._s(item.insertedBy) + " ")]), item.isRemoveable ? _c('v-col', {
      attrs: {
        "align": "right"
      }
    }, [_c('BaseConfirmDialog', {
      on: {
        "confirm": function confirm($event) {
          return _vm.$emit('removeById', item.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.deleteItemWarning) + " ")])], 1) : _vm._e()], 1)], 1)], 1);
  }), 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "align": "right"
    }
  }, [_c('v-btn', {
    staticClass: "mr-4",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.openAll
    }
  }, [_vm._v(" Åpne alle ")]), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.closeAll
    }
  }, [_vm._v(" Lukk alle ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }