
import { defineComponent, PropType, ref } from "@vue/composition-api";
import BaseConfirmDialog from "@/components/shared/BaseConfirmDialog.vue";

interface ExpansionPanelElement {
  header: string;
  content: string;
  id: number;
}

export default defineComponent({
  name: "BaseExpansionPanelList",
  components: { BaseConfirmDialog },
  emits: ["removeById"],
  props: {
    items: {
      type: Array as PropType<ExpansionPanelElement[]>,
      default: () => [],
    },
    deleteItemWarning: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const openPanels = ref<number[]>([]);

    const openAll = () => {
      openPanels.value = props.items.map((item, index) => index);
    };

    const closeAll = () => {
      openPanels.value = [];
    };

    return {
      openPanels,
      openAll,
      closeAll,
    };
  },
});
